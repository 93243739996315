body {
  background-color: #A2CCB8;

}

header {
  background-color: rgba(53, 53, 53, 0.52);
}

.header-slogan {
  color: white;
}

nav#nav-principal ul li ul li {

  position: relative;
  z-index: 1;
}

nav#nav-principal ul li a {
  color: white;
}

nav#nav-principal ul li:hover > a, nav#nav-principal ul li.actif > a {
  color: #35493F;
}

nav#nav-principal ul li a i, .home a:before, .livredor a:before, .contact a:before, .blog a:before {
  box-shadow: 0 2px 3px 0 #333131 inset, 1px 2px 3px 0 rgba(51, 49, 49, 0.01);
}

nav#nav-principal ul li a i, .home a:before, .livredor a:before, .contact a:before {
  /* background: none repeat scroll 0 0 #494848;*/
}


.button, .addmsglo {
  //color: white;
}

.produits .produit_etiquette:after {
  border-color: rgb(106, 126, 116) rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);

}

.produits .produit_etiquette {
  background: rgb(106, 126, 116);
}

.produits .produit_etiquette2 {
  background-color: rgb(106, 126, 116);

}

.produits .produit_etiquette2:after {
  border-color: rgba(0, 0, 0, 0) rgb(70, 86, 78) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

.but
.button:active, addmsglo:active {
  box-shadow: 0 0 #BC1E55;
  top: 6px;
}

.adr {
  color: #FFFFFF;
}

.message_lo {
  border: white;
}

.addcommentaires {
  color: black;
}


.photo {
  background-color: rgba(255, 255, 255, 0.1);
}

.produits {
  background-color: white;
}


#fiche-produit .wrap-description {
  background: none repeat scroll 0 0 #FFFFFF;
}

.wrap-description > h1,
.wrap-description > .h1 {
  background: #232323;
  color: white;
}

/*
    * Fils d'ariane
*/

.arianne a {
  background: #B3B5B7;
  color: #444;
}

nav#nav-principal ul li:before {
  background-color: #DBD0C3;

}

.message_lo .note {
  color: white;
}


.message_lo {
  background-color: #DEDFE0;
}


.addbasket:hover, .zoom:hover {
  background: #457F62;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  background-color: #457F62;
  border-color: #457F62;
}

.pagination > li > a, .pagination > li > span {
  color: #457F62;
}

.pagination.pull-right li.active a {
  color: #fff !important;
}

.bx-pager-item a {
  background: none repeat scroll 0 0 white;

}

#fiche-produit .wrap-description > h1,
#fiche-produit .wrap-description > .h1 {
  border-radius: 4px 5px 0 0;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active {
  background: none repeat scroll 0 0 black;
}

.arianne a:hover:after, .arianne a.actif:after, .arianne a:hover:before, .arianne a.actif:before {
  background: #6A7E74;
}

.arianne a:hover, .arianne a.actif {
  background: #6A7E74;
  color: white;
}


.border-title > span {
  background: none repeat scroll 0 0 black;
  border: 5px solid black;

}

.livre_dor .addmsglo, .userdigit .button, .livre_dor .button, .diaporama .button, .btn-details, #submit_code, .message_lo .note, #paiementchoix .button, .note_client, .select_style.produit-cat-similaire, .button {
  background: #2A7B7F;

}

#bx-pager img:hover {
  border: 2px solid #894138;
}

#bx-pager {
  background: none repeat scroll 0 0 rgba(211, 211, 211, 0.25);

}

.diaporama .button:hover, #paiementchoix .button:hover, .userdigit .button:hover, .livre_dor .button:hover, .livre_dor .addmsglo:hover, .button:hover {
  background: none repeat scroll 0 0 #155254;
  cursor: pointer;
}


.trianglenote {
  border-color: #155254 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);

}

.fa.fa-chevron-circle-right, .fa.fa-chevron-circle-left {
  color: #894138;
}


.fa.fa-chevron-circle-right:hover, .fa.fa-chevron-circle-left:hover {
  color: #155254;
}

.zoom, .addbasket {
  background: #6A7E74;

}

.produits-accueil .addbasket {

  border-left: 2px solid #35493F;
  width: 49%;
}

.arianne a, .arianne a:before, .arianne a:after {
  background: none repeat scroll 0 0 white;
  color: #6A7E74;

}

.produit_etiquette, .produit_etiquette2, .produit_etiquette3 {
  background-color: #6A7E74;
}

.produit_etiquette::after {
  border-color: #6A7E74 transparent transparent;
}

.is_etiquette .triangleremise, .triangleremise {
  border-color: transparent transparent transparent #6A7E74;
}

.triangle-ficheprod {
  border-color: transparent #6A7E74 transparent transparent;
}

.button.button-secondary {
  background: orange;

  &:hover {
    background: #444;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
  background: rgba(53, 53, 53, 0.5);
  border-color: rgba(53, 53, 53, 0.5);

  a {
    color: #fff;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
  border-color: rgba(53, 53, 53, 0.5);
  color: #222;

  a, span {
    color: #222;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
  color: #000;
}